<template>
  <v-dialog v-model="onboardDialog" width="80%" persistent>
    <v-card>
      <div v-if="user" class="profile-container">
        <setup-portfolio :user-type-list="user.userTypes" @closeModal="onboardDialog = false" />
      </div>
      <div v-else class="d-flex flex-row" style="position: relative">
        <v-btn v-if="!user" class="ma-5 modal-close" icon @click="onboardDialog = false">
          <v-icon x-large color="grey">mdi-close</v-icon>
        </v-btn>
        <login
          v-if="isLogin"
          :is-verified="!!$route.query.isVerified"
          is-dialog
          @customChange="changeView()"
        />
        <signup v-else is-dialog @signInModal="changeView()" />
      </div>
    </v-card>
  </v-dialog>
</template>

<script>
import { ref, computed, watchEffect } from '@vue/composition-api';
import { useUserState } from '@/store';

export default {
  components: {
    Login: () => import('@/views/Login.vue'),
    Signup: () => import('@/views/Signup.vue'),
    SetupPortfolio: () => import('@/views/Portfolio/SetupPortfolio.vue')
  },

  props: {
    value: {
      type: Boolean,
      default: false
    }
  },

  setup(props, ctx) {
    const onboardModal = ref(false);
    const isFetching = ref(false);
    const isLogin = ref(false);
    const isPreview = ref(false);
    const isVerified = ref(false);
    const { user } = useUserState(['user']);

    const onboardDialog = computed({
      get: () => props.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });

    function changeView(val) {
      isLogin.value = !isLogin.value;
    }

    watchEffect(() => {
      if (ctx.root.$route.query.isVerified) {
        onboardDialog.value = true;
        isLogin.value = true;
        isVerified.value = true;
      }
    });

    return {
      onboardModal,
      isVerified,
      isFetching,
      isLogin,
      isPreview,
      user,
      changeView,
      onboardDialog
    };
  }
};
</script>

<style lang="scss" scoped>
.modal-close {
  position: absolute;
  z-index: 1;
  right: 0;
}
.profile-container {
  margin-right: 10% !important;
  margin-left: 10% !important;
  display: flow-root;

  .setup-profile__content {
    padding: 4rem;
  }
}
</style>
